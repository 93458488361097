import revive_payload_client_vfLdNO77E3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._hcxnrtktngaxe5rjyomw5jj3li/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ArORP3vVxA from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._hcxnrtktngaxe5rjyomw5jj3li/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Rk0rlzg4Cz from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._hcxnrtktngaxe5rjyomw5jj3li/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_uQevCA6qqx from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._hcxnrtktngaxe5rjyomw5jj3li/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_4gIyPKUYYK from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._hcxnrtktngaxe5rjyomw5jj3li/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_YimYeEDnM9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._hcxnrtktngaxe5rjyomw5jj3li/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_t603em56p0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._hcxnrtktngaxe5rjyomw5jj3li/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/platform-admin/.nuxt/components.plugin.mjs";
import prefetch_client_NZoxqtSEMv from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.3.3__ioredis@5.4._hcxnrtktngaxe5rjyomw5jj3li/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_9gq5bINlCQ from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.22.5_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import tooltip_OuJWKcu5d4 from "/vercel/path0/node_modules/.pnpm/@docue+docue-ui-v2@3.2.0_magicast@0.3.5_nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.1_vxctasuawkacvirsf5vpnuhnre/node_modules/@docue/docue-ui-v2/ui-plugins/tooltip.ts";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/apps/platform-admin/plugins/bugsnag.ts";
export default [
  revive_payload_client_vfLdNO77E3,
  unhead_ArORP3vVxA,
  router_Rk0rlzg4Cz,
  payload_client_uQevCA6qqx,
  navigation_repaint_client_4gIyPKUYYK,
  check_outdated_build_client_YimYeEDnM9,
  chunk_reload_client_t603em56p0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NZoxqtSEMv,
  plugin_9gq5bINlCQ,
  tooltip_OuJWKcu5d4,
  bugsnag_tkMQfOjcVZ
]